import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1440p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "1440p CGI Commands",
  "path": "/1440p_Series_CGI_List/Alarm_Menu/Push_Service/",
  "dateChanged": "2020-03-18",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_1080p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "1440p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='1440p Series CGI List' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='1440p Series CGI List' image='/images/Search/AU_SearchThumb_CGICommands_1440p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1440p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1440p_Series_CGI_List/' locationFR='/fr/1440p_Series_CGI_List/' crumbLabel="Push" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-alarm-menu--push-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-alarm-menu--push-service",
        "aria-label": "the alarm menu  push service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Alarm Menu :: Push Service`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-alarm-menu--push-service"
        }}>{`The Alarm Menu :: Push Service`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetpushaction"
            }}>{`param.cgi?cmd=getpushaction`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#push-service-configuration"
                }}>{`Push Service Configuration`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgenpushid"
            }}>{`param.cgi?cmd=genpushid`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#generate-push-service-id"
                }}>{`Generate Push Service ID`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-1"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdpushid"
            }}>{`param.cgi?cmd=pushid`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#push-service-id"
                }}>{`Push Service ID`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-2"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdtestpushid"
            }}>{`param.cgi?cmd=testpushid`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#test-push-service-id"
                }}>{`Test Push Service ID`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-3"
                }}>{`Example`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <hr></hr>
    <h2 {...{
      "id": "paramcgicmdgetpushaction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetpushaction",
        "aria-label": "paramcgicmdgetpushaction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getpushaction`}</h2>
    <h3 {...{
      "id": "push-service-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#push-service-configuration",
        "aria-label": "push service configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Push Service Configuration`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`enable`}</code>{`: Enable / Disables push notification to the INSTAR companion app InstarVision `}{`[1, 0]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`interval`}</code>{`: Cooldown interval `}{`[5 - 60]`}</li>
    </ul>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getpushaction

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getpushaction"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`interval`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getpushaction`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ename`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getpushaction"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`interval`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setpushaction`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`interval`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`60`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setpushaction"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgenpushid",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgenpushid",
        "aria-label": "paramcgicmdgenpushid permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=genpushid`}</h2>
    <h3 {...{
      "id": "generate-push-service-id",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#generate-push-service-id",
        "aria-label": "generate push service id permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Generate Push Service ID`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`pushid`}</code>{`: Push ID`}</li>
    </ul>
    <h3 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`genpushid

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"genpushid"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdpushid",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdpushid",
        "aria-label": "paramcgicmdpushid permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=pushid`}</h2>
    <h3 {...{
      "id": "push-service-id",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#push-service-id",
        "aria-label": "push service id permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Push Service ID`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`pushid`}</code>{`: Push ID`}</li>
    </ul>
    <h3 {...{
      "id": "example-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-2",
        "aria-label": "example 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`pushid

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getpushid"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pushid`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"t2jx4353dasg7148dd7c292eui57a0bbg53sdf8f"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdtestpushid",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdtestpushid",
        "aria-label": "paramcgicmdtestpushid permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=testpushid`}</h2>
    <h3 {...{
      "id": "test-push-service-id",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#test-push-service-id",
        "aria-label": "test push service id permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Test Push Service ID`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`result`}</code>{`:`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`0`}</code>{`: `}<strong parentName="li">{`Success`}</strong></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-1`}</code>{`: `}<strong parentName="li">{`Can’t connect to Server`}</strong></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-2`}</code>{`: `}<strong parentName="li">{`Invalid credentials`}</strong></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-3`}</code>{`: `}<strong parentName="li">{`Permission error / Bad path`}</strong></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-4`}</code>{`: `}<strong parentName="li">{`Timeout`}</strong></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-5`}</code>{`: `}<strong parentName="li">{`Server cannot be verified`}</strong></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-6`}</code>{`: `}<strong parentName="li">{`Busy`}</strong></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-7`}</code>{`: `}<strong parentName="li">{`Internal error`}</strong></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`-8`}</code>{`: `}<strong parentName="li">{`Unknown error`}</strong></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "example-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-3",
        "aria-label": "example 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`testpushid

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"testpushid"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`result`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      